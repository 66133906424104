import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Template from '../components/subpageTemplate';
import styles from '../components/subpage.module.scss';
import { graphql, useStaticQuery } from 'gatsby';

const DrzwiWewnetrzneIZewnetrzne = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { shortcut: { eq: "drzwi" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Oferta - drzwi wewnętrzne i zewnętrzne" />
      <div className={styles.container}>
        {/*<h2>{data.markdownRemark.frontmatter.title}</h2>*/}
        {/*<Template data={data}/>*/}
        Dział w budowie
      </div>
    </Layout>
  );
};

export default DrzwiWewnetrzneIZewnetrzne;
